<template>
      <div class="faq-area black-bg pt-120 pb-90" 
      :style="{ backgroundImage:'url(' + require('../../assets/img/pattern/dot.png') + ')', }"
      data-background="assets/img/pattern/dot.png">
            <div class="container">
               <div class="row">
                  <div class="col-xl-4 col-lg-4">
                     <div class="sec-wrapper mb-30">
                        <h5>Κύκλος ζωής έργου</h5>
                        <h2 class="section-title text-white">Γιατί κάθε έργο μας έχει αρχή και τέλος</h2>
                        <p>Δεν φτιάχνουμε απλά websites, αλλά γρήγορες και αξιόπιστες εφαρμογές διαδικτύου που έχουν την αίσθηση εφαρμογών κινητού και επιτυγχάνουν την αλληλεπίδραση με τον χρήστη μέσω πεδίων επικοινωνίας.</p>
                        <div class="ab-btn mt-30">
                           <router-link to="/services#faqs-area" class="tp-btn">Συχνές ερωτήσεις</router-link>
                        </div>
                     </div>
                  </div>
                  <div class="col-xl-8 col-lg-8">
                     <div class="accordion" id="projectLifeCycle">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              Έρευνα & Ανάλυση
                            </button>
                          </h2>
                          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#projectLifeCycle">
                            <div class="accordion-body">
                              <p>Η έρευνα και η ανάλυση είναι το πιο σημαντικό βήμα στο ξεκίνημα ενός έργου. Περιλαμβάνει όλη την έρευνα που πρέπει να γίνει πριν από το σχεδιασμό και τη δημιουργία της δομής και του περιεχομένου του website ή της εφαρμογής γενικότερα. Πρέπει να ορίσουμε το κοινό στο οποίο αναφερόμαστε, το σκοπό του έργου, τις παρεχόμενες υπηρεσίες, τους μελλοντικούς στόχους και να εντοπίσουμε τους ανταγωνιστές. Επίσης, γίνονται εκτιμήσεις κόστους αλλά και χρόνου υλοποίησης κάθε φάσης του έργου.</p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              Σχεδιασμός Website / Εφαρμογής
                            </button>
                          </h2>
                          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#projectLifeCycle">
                            <div class="accordion-body">
                              <p>Αφού οριστούν οι απαιτήσεις του έργου, προχωράμε στον σχεδιασμό των επιμέρους οθονών του website και της διάταξης των διαφόρων στοιχείων που θα υποστηρίζει. <br/>
                              Έτσι, με βάση τα δεδομένα που συλλέγονται δημιουργoύμε έναν χάρτη ιστότοπου (sitemap), που την ουσία αποτελεί μια πανοραμική προβολή του website. Αυτός ο χάρτης μας λέει και ποιες σελίδες είναι οι πιο σημαντικές, ώστε μετά την υλοποίηση να ενημερωθούν και οι μηχανές αναζήτησης για αυτές.<br/>
                              Στη συνέχεια σχεδιάζουμε την διάταξη των στοιχείων μέσα στην οθόνη (wireframe). Το wireframe περιέχει λεπτομέρειες σχετικά με τις βασικές ενότητες στον ιστότοπο, όπως κουτάκια υπηρεσιών, πολυμέσων, πλαίσιο συνδρομών, φόρμες επικοινωνίας κ.ο.κ. Σύμφωνα με έρευνες, μεγάλο ποσοστό επισκεπτών του website αποχωρούν λόγω κακής σχεδίασης.</p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              Δημιουργία περιεχομένου
                            </button>
                          </h2>
                          <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#projectLifeCycle">
                            <div class="accordion-body">
                              <p>Το περιεχόμενο είναι το πιο σημαντικό κομμάτι ενός website. Πολλές φορές το καλύτερο περιεχόμενο φέρνει περισσότερα έσοδα. Όταν το περιεχόμενο σας είναι ελκυστικό, απλό κατανόηση και ξεκάθαρο, είναι εύκολο να πείσετε το κοινό σας για τις υπηρεσίες που προσφέρετε. Εάν το περιεχόμενο είναι ασαφές, δεν υπάρχει περίπτωση ο επισκέπτης να λάβει τις ακριβείς λεπτομέρειες της υπηρεσίας ή του προϊόντος και έτσι απλά θα αποχωρήσει χωρίς να κάνει καμία ενέργεια (πχ. αποστολή μηνύματος). Το περιεχόμενο πρέπει να είναι απλό, πιασάρικο και πειστικό.</p>
                            </div>
                          </div>
                        </div>
                         <div class="accordion-item">
                           <h2 class="accordion-header" id="headingThree1">
                             <button  class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree1" aria-expanded="false" aria-controls="collapseThree1">
                               Ανάπτυξη Λογισμικού
                             </button>
                           </h2>
                           <div id="collapseThree1" class="accordion-collapse collapse" aria-labelledby="headingThree1" data-bs-parent="#projectLifeCycle">
                             <div class="accordion-body">
                               <p>Σε αυτό το στάδιο γίνεται η μετατροπή όλων τον προηγούμενων βημάτων σε μια λειτουργική εφαρμογή. Πραγματοποιείται η ουσιαστική ανάπτυξη της εφαρμογής, ο προγραμματισμός. Αν και δεν θα μπούμε σε τεχνικές λεπτομέρειες, ο προγραμματισμός παίζει σημαντικό ρόλο στην ταχύτητα της εφαρμογής, την απόδοση, την μελλοντική επέκταση της εφαρμογής ακόμα και την υποστήριξη από τρίτους. Υπάρχουν δύο τμήματα που ξεχωρίζουμε στην ανάπτυξη της εφαρμογής: το Frontend και το Βackend. Συνοπτικά, το Frontend είναι αυτό που βλέπει ο επισκέπτης του website και το Βackend είναι το περιβάλλον διαχείρισης αλλά και προβολής πληροφοριών που αφορούν μόνο τον ιδιοκτήτη του website.</p>
                             </div>
                           </div>
                         </div>
                          <div class="accordion-item">
                           <h2 class="accordion-header" id="headingFive">
                             <button  class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                               Δοκιμές
                             </button>
                           </h2>
                           <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#projectLifeCycle">
                             <div class="accordion-body">
                               <p>Πριν δημοσιευτεί η εφαρμογή στο πραγματικό κοινό της, υποβάλλεται σε πολυάριθμες δοκιμές ποιότητας για να διασφαλιστεί μια καλύτερη εμπειρία χρήστη αλλά και να διορθωθούν τυχόν αστοχίες. Επίσης, οι δοκιμές διαδραματίζουν βασικό ρόλο στην ασφάλεια της εφαρμογής. Η εγφαρμογή θα είναι επιρρεπής σε πολλά τρωτά σημεία εάν δεν δοκιμαστεί σωστά. Οι δοκιμές περιλαμβάνουν μεταξύ άλλων: ελέγχους της ταχύτητας, ελέγχους ασφαλείας, εμφάνιση φιλική προς κινητές συσκευές, στρεσάρισμα της εφαρμογής κ.α. Αυτές οι δοκιμές πρέπει να γίνουν για να βεβαιωθούμε ότι ο ιστότοπος δεν έχει σφάλματα πουθενά.</p>
                             </div>
                           </div>
                         </div>
                      </div>
                  </div>
               </div>
            </div>
         </div>
</template>

<script>


export default {
    name:'HomeFaq'
}
</script>
