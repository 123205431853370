<template>
  <header>
    <div
      id="header-sticky"
      :class="`${
        isSticky
          ? 'header-area header-transparent header-pad header-border sticky'
          : 'header-area header-transparent header-pad header-border'
      }`"
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-xl-2 col-lg-2 col-6">
            <div class="logo logo-border">
              <router-link to="/">
                <img
                  class="logo-white"
                  src="../../assets/img/logo/logo.svg"
                  alt="logo"
                />
              </router-link>
            </div>
          </div>
          <div v class="col-xl-8 col-lg-8 d-none d-lg-block">
            <div class="main-menu text-center">
              <nav id="mobile-menu">
                <ul>
                  <li>
                    <router-link to="/landing"> Αρχική</router-link>
                  </li>
                  <li>
                    <router-link to="/about">Ποιοι Είμαστε</router-link>
                  </li>
                  <li>
                    <router-link to="/services">Υπηρεσίες</router-link>
                    <ul class="sub-menu">
                      <li>
                        <router-link to="/kataskevi-istoselidon"
                          >Κατασκευή Ιστοσελίδων</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/ecommerce-online-payments"
                          >Ecommerce / Online Payments</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/veltistopoihsi-istoselidas"
                          >Βελτιστοποίηση Ιστοσελίδων</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/ypostorixi-istoselidon"
                          >Υποστήριξη Ιστοσελίδων</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/services#digital-agencies"
                          >Υπηρεσίες για Digital Agencies</router-link
                        >
                      </li>
                    </ul>
                  </li>
                  <li>
                    <router-link to="/portfolio">Έργα</router-link>
                  </li>
                  <li>
                    <router-link to="/">Ψηφιακός Μετασχηματισμός</router-link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-6">
            <div
              class="
                header-right-info
                d-flex
                align-items-center
                justify-content-end
              "
            >
              <div class="sidebar__menu d-lg-none">
                <div
                  @click="menuOption.menuShow = !menuOption.menuShow"
                  class="sidebar-toggle-btn ml-30"
                  id="sidebar-toggle"
                >
                  <span class="line"></span>
                  <span class="line"></span>
                  <span class="line"></span>
                </div>
              </div>
              <div class="header-btn d-none d-lg-block">
                <router-link to="/contact" class="tp-btn"
                  >Επικοινωνία</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <!-- Mobile Menu Start -->
  <div class="fix">
    <div
      class="side-mobile-menu"
      :class="[menuOption.menuShow === true ? 'active' : '']"
    >
      <div class="sidebar__wrapper">
        <div
          @click="menuOption.menuShow = !menuOption.menuShow"
          class="sidebar__close"
        >
          <button class="sidebar__close-btn" id="sidebar__close-btn">
            <i class="fal fa-times"></i>
          </button>
        </div>
        <div class="sidebar__content">
          <div class="logo mb-40">
            <router-link to="/">
              <img src="../../assets/img/logo/logo-dark.svg" alt="logo" />
            </router-link>
          </div>
          <div class="mobile-menu fix">
            <div class="side-info-content sidebar-menu mm-menu">
              <ul>
                <li>
                  <router-link to="/landing">Αρχικη</router-link>
                </li>

                <li>
                  <router-link to="/about">Ποιοι Ειμαστε</router-link>
                </li>

                <li>
                  <router-link to="/portfolio">Εργα</router-link>
                </li>

                <li
                  class="menu-item-has-children has-droupdown"
                  v-bind:class="[
                    menuOption.homeDropdown === true ? 'active' : '',
                  ]"
                >
                  <a
                    v-on:click="
                      menuOption.homeDropdown = !menuOption.homeDropdown
                    "
                    >Υπηρεσιες</a
                  >
                  <ul
                    class="sub-menu"
                    v-bind:class="[
                      menuOption.homeDropdown === true ? 'active' : '',
                    ]"
                  >
                    <li>
                      <router-link to="/kataskevi-istoselidon">Κατασκευή Ιστοσελίδων</router-link>
                    </li>
                    <li>
                      <router-link to="/ecommerce-online-payments">Ecommerce / Online Payments</router-link>
                    </li>
                    <li>
                      <router-link to="/veltistopoihsi-istoselidas">Βελτιστοποίηση Ιστοσελίδων</router-link>
                    </li>
                    <li>
                      <router-link to="/ypostorixi-istoselidon">Υποστήριξη Ιστοσελίδων</router-link>
                    </li>
                    <li>
                      <router-link to="/services#digital-agencies">Υπηρεσίες για Digital Agencies</router-link>
                    </li>
                  </ul>
                </li>

                <li>
                  <router-link to="/contact" class="border-0"
                    >Επικοινωνια</router-link
                  >
                </li>
                <li>
                  <router-link to="/" class="border-0"
                    >Ψηφιακός Μετασχηματισμός</router-link
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="sidebar__contact mt-30 mb-45">
            <ul>
              <li>
                <div class="icon theme-color">
                  <i class="fal fa-envelope"></i>
                </div>
                <div class="text theme-color">
                  <span
                    ><a href="mailto:hello@askite.com"
                      >hello@askite.com</a
                    ></span
                  >
                </div>
              </li>

              <li>
                <div class="icon theme-color">
                  <i class="fas fa-phone-volume"></i>
                </div>
                <div class="text theme-color">
                  <span><a href="tel:+302663071953">+30 2663-071-953</a></span>
                </div>
              </li>

              <li>
                <div class="icon">
                  <i class="fal fa-map-marker-alt"></i>
                </div>
                <div class="text">
                  <a
                    target="_blank"
                    href="https://www.google.com/maps/place/ASK+IT+Engineering/@39.6996166,19.7277179,17.05z/data=!4m8!1m2!2m1!1saskite+!3m4!1s0x14a839e187088213:0x6266dff0cb10bd8!8m2!3d39.6989598!4d19.7265461"
                    >Αλειμματάδες 49083 Κέρκυρα</a
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="body-overlay transition-3"
    :class="[menuOption.menuShow === true ? 'opened' : '']"
  ></div>
  <!-- Mobile Menu End -->
</template>

<script>
export default {
  name: "HomeHeader",
  data() {
    return {
      isSticky: false,
      searchPopup: false,
      menuOption: {
        menuShow: false,
        menuSearch: false,
        homeDropdown: false,
        serviceDropdown: false,
        pagesDropDown: false,
        blogDropdown: false,
        aboutDropdown: false,
      },
    };
  },
  methods: {
    handleSticky() {
      if (window.scrollY > 80) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    handleSearch() {
      this.searchPopup = true;
    },
    handleClose() {
      this.searchPopup = false;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleSticky);
  },
};
</script>

