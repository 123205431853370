<template>
    <footer :style="{backgroundImage:'url('+ require('../../assets/img/bg/footer-bg.jpg') + ')'}" class="footer-2 pt-95 position-relative">
            <div class="common-shape-wrapper">
               <div class="common-shape-inner"></div>
            </div>
            <div class="footer-area pb-60">
               <div class="container">
                  <div class="row">
                        <div class="col-lg-4 col-sm-6">
                           <div class="widget mb-30">
                              <div class="footer-logo mb-25">
                                    <router-link to="/">
                                       <img src="../../assets/img/logo/logo.svg" class="img-fluid" alt="footer-logo">
                                    </router-link>
                              </div>
                              <p class="mb-20 pr-35">Αποτελούμε μια ποικιλόμορφη ομάδα με αντικείμενο τον σχεδιασμό ιστοσελίδων, την ανάπτυξη διαδικτυακών εφαρμογών, το ηλεκτρονικό εμπόριο, την ασφάλεια και άλλες διαδικτυακές υπηρεσίες.</p>
                              <div class="footer-social footer-social-2">
                                    <a href="https://www.facebook.com/askitengineering/" target="_blank"><i class="fab fa-facebook"></i></a>
                                    <a id="researchgate" href="https://www.researchgate.net/profile/Alexandros-Katsaros-4" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 32v448h448V32H0zm262.2 334.4c-6.6 3-33.2 6-50-14.2-9.2-10.6-25.3-33.3-42.2-63.6-8.9 0-14.7 0-21.4-.6v46.4c0 23.5 6 21.2 25.8 23.9v8.1c-6.9-.3-23.1-.8-35.6-.8-13.1 0-26.1.6-33.6.8v-8.1c15.5-2.9 22-1.3 22-23.9V225c0-22.6-6.4-21-22-23.9V193c25.8 1 53.1-.6 70.9-.6 31.7 0 55.9 14.4 55.9 45.6 0 21.1-16.7 42.2-39.2 47.5 13.6 24.2 30 45.6 42.2 58.9 7.2 7.8 17.2 14.7 27.2 14.7v7.3zm22.9-135c-23.3 0-32.2-15.7-32.2-32.2V167c0-12.2 8.8-30.4 34-30.4s30.4 17.9 30.4 17.9l-10.7 7.2s-5.5-12.5-19.7-12.5c-7.9 0-19.7 7.3-19.7 19.7v26.8c0 13.4 6.6 23.3 17.9 23.3 14.1 0 21.5-10.9 21.5-26.8h-17.9v-10.7h30.4c0 20.5 4.7 49.9-34 49.9zm-116.5 44.7c-9.4 0-13.6-.3-20-.8v-69.7c6.4-.6 15-.6 22.5-.6 23.3 0 37.2 12.2 37.2 34.5 0 21.9-15 36.6-39.7 36.6z"/></svg></a>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                           <div class="widget mb-30">
                              <h4 class="widget-title mb-35">Υπηρεσίες</h4>
                              <ul>
                                    <li><router-link to="/kataskevi-istoselidon">Κατασκευή Ιστοσελίδων</router-link></li>
                                    <li><router-link to="/ecommerce-online-payments">Ecommerce / Online Payments</router-link></li>
                                    <li><router-link to="/veltistopoihsi-istoselidas">Βελτιστοποίηση Ιστοσελίδων</router-link></li>
                                    <li><router-link to="/ypostorixi-istoselidon">Υποστήριξη Ιστοσελίδων</router-link></li>
                              </ul>
                           </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                           <div class="widget mb-30">
                              <h4 class="widget-title mb-35">Digital Agencies</h4>
                              <ul>
                                    <li><router-link to="/html-to-wordpress">HTML to Wordpress</router-link></li>
                                    <li><router-link to="/psd-to-html">PSD to HTML</router-link></li>
                                    <li><router-link to="/apis-integrations">APIs & Integrations</router-link></li>
                                    <li><router-link to="/woocommerce-opencart">Woocommerce & Opencart</router-link></li>
                              </ul>
                           </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                           <div class="widget widget-contact mb-30">
                              <h4 class="widget-title mb-35">Επικοινωνία</h4>
                              <ul>
                                    <li class="pb-10"><a target="_blank" href="https://www.google.com/maps/place/ASK+IT+Engineering/@39.6996166,19.7277179,17.05z/data=!4m8!1m2!2m1!1saskite+!3m4!1s0x14a839e187088213:0x6266dff0cb10bd8!8m2!3d39.6989598!4d19.7265461" >Αλειμματάδες 49083 Κέρκυρα</a></li>
                                    <li><i class="fal fa-envelope-open"></i><a href="mailto:hello@askite.com">hello@askite.com</a></li>
                                    <li><i class="fal fa-phone-alt"></i><a href="tel:+302663071953">+30 2663-071-953</a></li>
                              </ul>
                           </div>
                        </div>
                  </div>
               </div>
            </div>
            <div class="copyright-area">
               <div class="container">
                  <div class="copyright-bg">
                        <div class="row align-items-center">
                           <div class="col-md-6">
                              <div class="copyright">
                                    <span>Copyright ©2023 ASK IT Engineering.<br/>Απαγορεύεται ρητά η αντιγραφή η αναδημοσίευση του υλικού του ιστότοπου.</span>
                              </div>
                           </div>
                           <div class="col-md-6">
                              <div class="privacy-text text-md-end"> 
                                    <ul>
                                       <li>
                                          <router-link to="/services#faqs-area">Συχνές ερωτήσεις</router-link>
                                       </li>
                                    </ul>
                              </div>
                           </div>
                        </div>
                  </div>
               </div>
            </div>
         </footer>
</template>

<script>
export default {
    name:'HomeTwoFooter',
}
</script>