<template>
     <div class="main-services grey-bg pt-120 pb-90" :style="{backgroundImage:'url(' + require('../../assets/img/pattern/pt1.png') +')'}">
            <div class="container">
               <div class="row mb-60">
                  <div class="col-12">
                     <div class="sec-wrapper text-center">
                        <h5>Με μια ματιά</h5>
                        <h2 class="section-title">Οι Υπηρεσίες μας</h2>
                     </div>
                  </div>
               </div>
               <div class="row text-center">
                  <div v-for="service in servicesData" :key="service.id" class="col-xl-3 col-lg-3 col-md-6 mb-30">
                     <div class="mfbox mfbox-white">
                        <router-link :to="service.link">
                           <div class="mf-shape"></div>
                           <div class="mfbox__icon mb-15">
                              <i :class="service.icon"></i>
                           </div>
                           <div class="mfbox__text">
                              <h3 class="mf-title" v-html="service.title"></h3>
                              <p v-html="service.summary"></p>   
                           </div>
                            <div class="mf-btn">
                              <div class="squire-btn">
                                 <i class="fal fa-angle-right"></i>
                              </div>
                           </div>
                        </router-link>
                     </div>
                  </div>
                  
               </div>
               <div class="row">
                  <div class="col-12">
                     <router-link to="/services#digital-agencies" class="tp-btn float-end">Πατήστε εδώ για Υπηρεσίες Digital Agencies</router-link>
                  </div>
               </div>
            </div>
         </div>
</template>

<script>
export default {
    name:'ServicesArea',
    props:{
        class:String
    },
    data () {
      return {
         servicesData:[
            {
               id:'1',
               icon:'flaticon-insight',
               title:'Κατασκευή Ιστοσελίδων',
               summary: 'Μοναδικές λύσεις ανεξάρτητα από τον κλάδο που δραστηριοποιείται η επιχείρηση σας.',
               link: 'kataskevi-istoselidon'
            },
            {
               id:'2',
               icon:'flaticon-ecommerce',
               title:'Ecommerce / Online Payments',
               summary: 'Σε μια κοινωνία που συνεχώς εξελίσσεται προκύπτει η ανάγκη ύπαρξης ηλεκτρονικών πληρωμών.',
               link: 'ecommerce-online-payments'
            },
            {
               id:'3',
               icon:'flaticon-web-optimization',
               title:'Βελτιστοποίηση Ιστοσελίδας',
               summary: 'Μείωση χρόνου απόκρισης μιας σελίδας καθώς και χρόνου αλληλεπίδρασης με τον χρήστη.',
               link: 'veltistopoihsi-istoselidas'
            },
            {
               id:'4',
               icon:'flaticon-technical-support',
               title:'Υποστήριξη Ιστοσελίδων',
               summary: 'Τεχνική ή συμβουλευτική υποστήριξη για την ιστοσελίδα ή το ηλεκτρονικό σας κατάστημα.',
               link: 'ypostorixi-istoselidon'
            },
         ]
      }
   }
}
</script>