<template>
  <div id="faqs-area" class="faq-area pt-120 pb-90 grey-bg">
    <div class="container">
      <div class="row mb-60">
        <div class="col-12">
            <div class="sec-wrapper text-center">
              <h2 class="section-title">Συχνές ερωτήσεις</h2>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <div class="faq-content faq-white">
            <div class="accordion" id="faqsList">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Πόσο κοστίζει μια ιστοσελίδα;
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#faqsList"
                >
                  <div class="accordion-body">
                    <p>
                      Το κόστος μιας ιστοσελίδας δεν είναι σταθερό, αλλά εξαρτάται από πολλούς παράγοντες. Το κόστος υπολογίζεται μετά από την ανάλυση του έργου και την καταγραφή όλων των λειτουργικών απαιτήσεων. Η ύπαρξη κειμένων και φωτογραφιών είναι άλλος ένα παράγοντας που επηρεάζει το κόστος. Αν δεν υπάρχουν μπορούμε να αναλάβουμε είτε εμείς την επιμέλεια των κειμένων είτε εσείς. Η ύπαρξη ή όχι online πληρωμών, επίσης επηρεάζει το κόστος.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Πόσο χρόνο χρειάζεται για να ολοκληρωθεί;
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#faqsList"
                >
                  <div class="accordion-body">
                    <p>
                      Η παραπάνω διαδικασία, η οποία καθορίζει το κόστος, είναι η ίδια με την οποία γίνεται και η εκτίμηση χρόνου υλοποίησης. Ο χρόνος ποικίλλει συνήθως από 1 ως και 6 μήνες.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree3">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree3"
                    aria-expanded="false"
                    aria-controls="collapseThree3"
                  >
                    Τι θα χρεαστείτε από εμένα;
                  </button>
                </h2>
                <div
                  id="collapseThree3"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree3"
                  data-bs-parent="#faqsList"
                >
                  <div class="accordion-body">
                    <p>Εξαρτάται από το είδος του website αλλά και από την συμφωνία που θα κάνουμε μαζί πριν την έναρξη των εργασιών. Παράδειγμα περιεχεμένου που συνήθως χρειάζεται είναι:</p>
                    <ul>
                      <li>Φωτογραφίες (από επαγγελματία φωτογράφο) του χώρου εργασίας σας</li>
                      <li>Φωτογραφίες (από επαγγελματία φωτογράφο) της ομάδας εργασίας</li>
                      <li>Κείμενο περιγραφής της εταιρίας και των υπηρεσιών που προφέρονται</li>
                      <li>Συγκριτικά πλεονεκτήματα</li>
                      <li>Γραφιστικό υλικό που πιθανόν να υπάρχει</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Μπορώ να διαχειρίζομαι μόνος/μόνη το περιεχόμενο της
                    ιστοσελίδας;
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#faqsList"
                >
                  <div class="accordion-body">
                    <p>
                      Μετά από λίγες ώρες εκμάθησης, που προσφέρουμε δωρεάν, είστε σε θέση να κάνετε σύνδεση με κωδικούς και να διαχειρίζεστε το περιεχόμενο των υποσελίδων σας. Βέβαια, αν το επιθυμείτε μπορούμε να αναλάβουμε εμείς την διαχείριση.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Πως μπορώ να έχω επαγγελματικό email (πχ. info@mydomain.gr) και πόσο κοστίζει;
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#faqsList"
                >
                  <div class="accordion-body">
                    <p>
                      Μπορούμε να δημιουργήσουμε για εσάς επαγγελματικό email στη Google ή στην Microsoft. Με αυτόν τον τρόπο θα είστε σίγουροι ότι δεν θα χαθεί κάποιο μήνυμα σας, θα έχετε αρκετό χώρο για τα μηνύματα και τα συνημμένα σας και επίσης δεν θα λαμβάνετε πλήθος spam γιατί παρέχονται αποδοτικοί μηχανισμοί για να τα φιλτράρουν. To κόστος για ένα email της Google είναι 5,60€ και της Microsoft 5,1 €.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Υπάρχουν ετήσια/μηνιαία κόστη που πρέπει να πληρώνω;
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#faqsList"
                >
                  <div class="accordion-body">
                    <p>
                      Υπάρχουν κάποιες συνδρομές που δεν μπορείτε να αποφύγετε και άλλες όπου είναι προαιρετικές. Στις προαιρετικές περιλαμβάνεται το κόστος υποστήριξη της ιστοσελίδας, ώστε να μπορούμε να φροντίζουμε για την καλή λειτουργία και για τυχόν λειτουργικά ζητήματα που μπορεί να προκύψουν.Στις υποχρεωτικές περιλαμβάνονται το κόστος για την ενοικίαση του ονόματος χώρου (domain name) περίπου 12€ ανα έτος και η φιλοξενία της ιστοσελίδας (web hosting) περίπου 150€ ανα έτος για ιστοσελίδες με χαμηλή κατανάλωση πόρων.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Τι είναι το Web Hosting και πόσο κοστίζει;
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#faqsList"
                >
                  <div class="accordion-body">
                    <p>
                      Η φιλοξενία της ιστοσελίδας (web hosting) αφορά στους πόρους που χρησιμοποιούνται από την εκάστοτε εφαρμογή στο υπολογιστικό νέφος (cloud). Για παράδειγμα, ένα ηλεκτρονικό κατάστημα χρησιμοποιεί πολύ περισσότερους πόρους για να λειτουργεί από ότι μια ιστοσελίδα εταιρικής παρουσίασης. Το κόστος της φιλοξενίας της ιστοσελίδας είναι περίπου 150€ ανα έτος για ιστοσελίδες με χαμηλή κατανάλωση πόρων.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSeven">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    Υπάρχει κάποιο πλάνο πληρωμής;
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#faqsList"
                >
                  <div class="accordion-body">
                    <p>Ναι, συνήθως χωρίζουμε την πληρωμή σε 2 δόσεις. Το πρώτο 50% αφού υπογράψουμε το μεταξύ μας συμφωνητικό εργασιών (κατά την έναρξη του έργου) και το τελικό 50% αφού ολοκληρωθούν οι εργασίες και ο ποιοτικός έλεγχος.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqArea",
};
</script>