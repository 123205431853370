<template>
     <section class="contact-area pt-120 pb-80 fix">
         <div class="container">
             <div class="row">
                 <div class="col-xxl-5 col-xl-6 col-lg-6">
                     <div class="section-title-wrapper mb-15">
                         <h5 class="section-subtitle mb-20">Επικοινωνία</h5>
                         <h2 class="section-title">Μιλήστε με τον ειδικό</h2>
                     </div>
                     <div class="contact-info mr-50 mr-xs-0  mr-md-0">
                         <div class="single-contact-info d-flex align-items-center">
                             <div class="contact-info-icon">
                                 <a href="#"><i class="fad fa-comments"></i></a>
                             </div>
                             <div class="contact-info-text mt-10">
                                 <span>τηλεφωνικά</span>
                                 <h5><a href="tell: +302663071953">+30 2663-071-953</a></h5>
                             </div>
                         </div>
                         <div class="single-contact-info d-flex align-items-center">
                             <div class="contact-info-icon">
                                 <a href="#"><i class="fal fa-envelope"></i></a>
                             </div>
                             <div class="contact-info-text mt-10">
                                 <span>με email</span>
                                 <h5><a href="mailto:hello@askite.com">hello@askite.com</a> </h5>
                             </div>
                         </div>
                         <div class="single-contact-info d-flex align-items-center">
                             <div class="contact-info-icon">
                                 <a href="#"><i class="fal fa-map-marker-alt"></i></a>
                             </div>
                             <div class="contact-info-text mt-10">
                                 <span>στον χώρο μας</span>
                                 <h5><a target="_blank" href="https://www.google.com/maps/place/ASK+IT+Engineering/@39.6996166,19.7277179,17.05z/data=!4m8!1m2!2m1!1saskite+!3m4!1s0x14a839e187088213:0x6266dff0cb10bd8!8m2!3d39.6989598!4d19.7265461">Αλειμματάδες 49083 Κέρκυρα</a></h5>
                             </div>
                         </div>
                     </div>
                 </div>
                 <div class="col-xxl-7 col-xl-6 col-lg-6">
                     <div class="contact-form">
                         <form @submit="formSubmit" id="contact-form" method="POST">
                             <div class="row">
                                 <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                                     <input name="name" type="text" placeholder="Όνομα" v-model="name">
                                 </div>
                                 <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                                     <input name="email" type="email" placeholder="Email" v-model="email">
                                 </div>
                                 <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                                     <input name="phone" type="text" placeholder="Τηλέφωνο" v-model="phone">
                                 </div>
                                 <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                                     <input name="subject" type="text" placeholder="Θέμα" v-model="subject">
                                 </div>
                                 <div class="col-xxl-12 col-xl-12 col-lg-12 mb-20">
                                     <textarea placeholder="Μήνυμα" name="massage" v-model="message"></textarea>
                                 </div>
                                 <div class="col-xxl-12 col-xl-12 mb-20">
                                    <input type="text" name="email2" v-model="email2" style="display:none" />
                                     <button type="submit" class="tp-btn">Αποστολή</button>
                                 </div>
                             </div>
                         </form>
                         <p class="ajax-response"></p>
                     </div>
                 </div>
             </div>
         </div>
    </section>
</template>

<script>
export default {
    name:'ContactArea',
    data() {
        return {
            name: '',
            email: '',
            email2: '',
            phone: '',
            subject: '',
            message: '',
            output: ''
        };
    },
    methods: {
        formSubmit(e) {
            e.preventDefault();
            let currentObj = this;
            this.axios.post('https://askite.com/mail.php', {
                name: this.name,
                email: this.email,
                email2: this.email2,
                phone: this.phone,
                subject: this.subject,
                message: this.message
            })
            .then(function (response) {
                currentObj.output = response.data;
            })
            .catch(function (error) {
                currentObj.output = error;
            });
        }
    }
}
</script>