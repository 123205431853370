<template>
        <section class="service-detals pt-120 pb-100 fix">
            <div class="container">
               <div class="row">
                  <div class="col-xxl-8 col-xl-8 col-lg-8">
                        <div class="develop-wrapper">
                           <div class="develop-content">
                              <p>Παραλαμβάνουμε PSD, Sketch, Adobe Illustrator, Zeplin, Invision αρχεία εικαστικών από τον γραφίστα σας και τα μετατρέπουμε σε μια πλήρως λειτουργική ιστοσελίδα. Αποτυπώνουμε κάθε λεπτομέρεια των εικαστικών σας στο HTML/CSS. Το HTML/CSS υλοποιείται με Bootstrap Grid Layout ώστε να είναι εύκολα διαχειρίσιμο και επεκτάσιμο από κάθε ειδικό του αντικειμένου. Δοκιμάζουμε την λειτουργία του σε όλους τους γνωστούς browsers και λειτουργικά συστήματα. Επίσης, ελέγχεται η σωστή λειτουργία και προσαρμογή σε διαφορετικά μεγέθη οθονών.</p>
                           </div>
                        </div>

                        <div class="planning-wrapper pt-50">
                           <div class="row">
                              <div class="col-xxl-6 col-xl-6 col-lg-6">
                                    <div class="plan-thumb mb-md-40 mb-xs-40">
                                       <img src="../../assets/img/service/service-img-6-5.jpg" alt="">
                                    </div>
                              </div>
                              <div class="col-xxl-6 col-xl-6 col-lg-6">
                                    <div class="plan-content">
                                       <h4>Χαρακτηριστικά Υπηρεσίας</h4>
                                       <ul>
                                          <li>Pixel Perfect</li>
                                          <li>Bootstrap Grid Layout</li>
                                          <li>Cross Browser</li>
                                          <li>Responsive Designs</li>
                                          <li>No Javascript Version</li>
                                          <li>Minimal Javascript Payload</li>
                                          <li>Accessibility</li>
                                          <li>Lazy load images</li>
                                       </ul>
                                    </div>
                              </div>

                              <div class="col-xxl-12">
                                    <div class="plan-content pr-10 pt-50 pt-xs-30 pt-md-30 pb-15">
                                       <p>Η χρήση javascript γίνεται όπου είναι αναγκαίο και μόνο, έτσι ώστε να μειωθεί το μέγεθος της ιστοσελίδας και να πετύχουμε μικρούς χρόνους φόρτωσης. Για τον ίδιο λόγο οι φωτογραφίες φορτώνονται μόνο όταν βρεθούν εντός οθόνης.</p>
                                       <p>Ιδιαίτερη προσοχή δίνεται επίσης και στην προσβασιμότητα. Ο χρήστης μπορεί να περιηγηθεί στη σελίδα χρησιμοποιώντας μόνο το πληκτρολόγιο. Χρήση aria attributes και rem μεγεθών. Επίσης, υλοποίηση σύμφωνα με το wcag 2 AA πρότυπο.</p>
                                    </div>
                              </div>
                           </div>
                        </div>
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4">
                        <div class="sidebar-wrap">
                           <div class="widget_categories grey-bg">
                              <h4 class="bs-widget-title pl-20">Υπηρεσίες για Digital Agencies</h4>
                              <ul>
                                    <li><router-link to="/html-to-wordpress">HTML to Wordpress</router-link></li>
                                    <li><router-link to="/psd-to-html">PSD to HTML</router-link></li>
                                    <li><router-link to="/apis-integrations">APIs & Integrations</router-link></li>
                                    <li><router-link to="/woocommerce-opencart">Custom Woocommerce & Opencart Builds</router-link></li>
                                    <li><router-link to="/services">Άλλες υπηρεσίες</router-link></li>
                              </ul>
                           </div>
                           <div class="widget-btn mt-30">
                              <router-link to="/contact" class="tp-btn w-100">Ζητήστε προσφορά</router-link>
                           </div>
                        </div>
                  </div>
               </div>
            </div>
         </section>
</template>

<script>
export default {
    name:'ServicesDetailsArea'
}
</script>