<template>
        <section class="service-detals pt-120 pb-100 fix">
            <div class="container">
               <div class="row">
                  <div class="col-xxl-8 col-xl-8 col-lg-8">
                        <div class="develop-wrapper">
                           <div class="develop-content">
                              <p>Μοναδικές λύσεις για ξεχωριστές απαιτήσεις. Ανεξάρτητα από τον κλάδο που δραστηριοποιείται η επιχείρηση σας μπορούμε να σας προσφέρουμε ένα μοναδικό μέσο επικοινωνίας με τους πελάτες σας, ώστε να βρίσκουν πάντα αυτό που ζητούν χωρίς κόπο. Προσφέρετε στο κοινό σας ψηφιακές ευκολίες ώστε να δημιουργηθεί μια αλληλένδετη σχέση μεταξύ σας.</p>
                           </div>
                        </div>

                        <div class="planning-wrapper pt-50">
                           <div class="row">
                              <div class="col-xxl-6 col-xl-6 col-lg-6">
                                    <div class="plan-thumb mb-md-40 mb-xs-40">
                                       <img src="../../assets/img/service/service-img-6.jpg" alt="">
                                    </div>
                              </div>
                              <div class="col-xxl-6 col-xl-6 col-lg-6">
                                    <div class="plan-content">
                                       <h4>Χαρακτηριστικά Υπηρεσίας</h4>
                                       <ul>
                                          <li>Χρηστικότητα</li>
                                          <li>Σταθερότητα</li>
                                          <li>Επεκτασιμότητα</li>
                                          <li>Ευκολία διαχείρισης</li>
                                       </ul>
                                    </div>
                              </div>

                              <div class="col-xxl-12">
                                    <div class="plan-content pr-10 pt-50 pt-xs-30 pt-md-30 pb-15">
                                       <p>Τα πρώτα βήματα για την δημιουργία μιας ιστοσελίδας είναι η καταγραφή του συνόλου των λειτουργικών απαιτήσεων και γενικότερα όλων των σκέψεών σας γύρω από την ιδέα σας για την ιστοσελίδα.</p>
                                       <p>Το μότο μας είναι “Make your thoughts come Web”, γι’ αυτό τολμήστε να εκφράσετε οποιαδήποτε σκέψη σας, η οποία μπορεί να μας βοηθήσει να καταλάβουμε την λειτουργία και την αισθητική του έργου σας.</p>
                                    </div>
                              </div>
                           </div>
                        </div>

                        <div class="choose-right aos-init aos-animate" data-aos="fade-left" data-aos-duration="1000">
                           <div class="accordion" id="accordionExample">
                              <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                       <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                          Πόσο κοστίζει μια ιστοσελίδα;
                                       </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style="">
                                       <div class="accordion-body text-white">
                                          <p>Το κόστος μιας ιστοσελίδας δεν είναι σταθερό, αλλά εξαρτάται από πολλούς παράγοντες. Το κόστος υπολογίζεται μετά από την ανάλυση του έργου και την καταγραφή όλων των λειτουργικών απαιτήσεων. Η ύπαρξη κειμένων και φωτογραφιών είναι άλλος ένα παράγοντας που επηρεάζει το κόστος. Αν δεν υπάρχουν μπορούμε να αναλάβουμε είτε εμείς την επιμέλεια των κειμένων είτε εσείς. Η ύπαρξη ή όχι online πληρωμών, επίσης επηρεάζει το κόστος.</p>
                                       </div>
                                    </div>
                              </div>
                              <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo">
                                       <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                          Πόσο χρόνο χρειάζεται για να ολοκληρωθεί;
                                       </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style="">
                                       <div class="accordion-body text-white">
                                          <p>Η παραπάνω διαδικασία, η οποία καθορίζει το κόστος, είναι η ίδια με την οποία γίνεται και η εκτίμηση χρόνου υλοποίησης. Ο χρόνος ποικίλλει συνήθως από 1 ως και 6 μήνες.</p>
                                       </div>
                                    </div>
                              </div>
                              <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                       <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                          Τι θα χρεαστείτε από εμένα;
                                       </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" style="">
                                       <div class="accordion-body text-white">
                                          <p>Εξαρτάται από το είδος του website αλλά και από την συμφωνία που θα κάνουμε μαζί πριν την έναρξη των εργασιών. Παράδειγμα περιεχεμένου που συνήθως χρειάζεται είναι:</p>
                                          <ul>
                                             <li>Φωτογραφίες (από επαγγελματία φωτογράφο) του χώρου εργασίας σας</li>
                                             <li>Φωτογραφίες (από επαγγελματία φωτογράφο) της ομάδας εργασίας</li>
                                             <li>Κείμενο περιγραφής της εταιρίας και των υπηρεσιών που προφέρονται</li>
                                             <li>Συγκριτικά πλεονεκτήματα</li>
                                             <li>Γραφιστικό υλικό που πιθανόν να υπάρχει</li>
                                          </ul>
                                       </div>
                                    </div>
                              </div>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-12">
                              <router-link to="/services#faqs-area" class="tp-btn float-end">Έχετε περισσότερες απορίες;</router-link>
                           </div>
                        </div>
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4">
                        <div class="sidebar-wrap">
                           <div class="widget_categories grey-bg">
                              <h4 class="bs-widget-title pl-20">Υπηρεσίες</h4>
                              <ul>
                                    <li><router-link to="/kataskevi-istoselidon">Κατασκευή Ιστοσελίδων</router-link></li>
                                    <li><router-link to="/ecommerce-online-payments">Ecommerce / Online Payments</router-link></li>
                                    <li><router-link to="/veltistopoihsi-istoselidas">Βελτιστοποίηση Ιστοσελίδων</router-link></li>
                                    <li><router-link to="/ypostorixi-istoselidon">Υποστήριξη Ιστοσελίδων</router-link></li>
                                    <li><router-link to="/services#digital-agencies">για Digital Agencies</router-link></li>
                              </ul>
                           </div>
                           <div class="widget-btn mt-30">
                              <router-link to="/contact" class="tp-btn w-100">Ζητήστε προσφορά</router-link>
                           </div>
                        </div>
                  </div>
               </div>
            </div>
         </section>
</template>

<script>
export default {
    name:'ServicesDetailsArea'
}
</script>