<template>
        <section class="service-detals pt-120 pb-100 fix">
            <div class="container">
               <div class="row">
                  <div class="col-xxl-8 col-xl-8 col-lg-8">
                        <div class="develop-wrapper">
                           <div class="develop-content">
                              <p>Χιλιάδες νέα woocommerce shops δημιουργούνται ετησίως, καθώς το woocommerce είναι μια αξιόπιστη και χρηστική λύση για ηλεκτρονικά καταστήματα που αριθμούν από 1 ως μερικές εκατοντάδες προϊόντα.</p>
                              <p>Υποστηρίζει πλήρως διασυνδέσεις με μεταφορικές και τράπεζες, προσφέροντας μια ολοκληρωμένη λύση ecommerce που να μπορεί να υλοποιηθεί σε σχετικά μικρό διάστημα.</p>
                              <p>Κάνοντας χρήση των template αρχείων του woocommerce και ιδιαίτερα των woocommerce hooks υλοποιούμε το ηλεκτρονικό κατάστημα σύμφωνα με τα εικαστικά σας, προσφέροντας έτσι την επιθυμητή χρήστη στους επισκέπτες σας.</p>
                           </div>
                        </div>
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4">
                        <div class="sidebar-wrap">
                           <div class="widget_categories grey-bg">
                              <h4 class="bs-widget-title pl-20">Υπηρεσίες για Digital Agencies</h4>
                              <ul>
                                    <li><router-link to="/html-to-wordpress">HTML to Wordpress</router-link></li>
                                    <li><router-link to="/psd-to-html">PSD to HTML</router-link></li>
                                    <li><router-link to="/apis-integrations">APIs & Integrations</router-link></li>
                                    <li><router-link to="/woocommerce-opencart">Custom Woocommerce & Opencart Builds</router-link></li>
                                    <li><router-link to="/services">Άλλες υπηρεσίες</router-link></li>
                              </ul>
                           </div>
                           <div class="widget-btn mt-30">
                              <router-link to="/contact" class="tp-btn w-100">Ζητήστε προσφορά</router-link>
                           </div>
                        </div>
                  </div>
               </div>
            </div>
         </section>
</template>

<script>
export default {
    name:'ServicesDetailsArea'
}
</script>