<template>
    <div class="about-area pt-120 pb-90">
            <div class="container">
               <div class="row">
                  <div class="col-xl-6 col-lg-6">
                     <div class="ab-wrapper pos-rel">
                        <div class="ab-since">
                           <h4>Από το <br> 2013</h4>
                        </div>
                        <div class="row gx-2 mb-30">
                           <div class="col-6">
                              <div class="ab-img img-filter">
                                 <img src="../../assets/img/about/abs-sm3.jpg" alt="">
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="ab-img img-filter">
                                 <img src="../../assets/img/about/abs-sm1.jpg" alt="">
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="ab-img img-filter">
                                 <img src="../../assets/img/about/abs-sm2.jpg" alt="">
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="ab-img img-filter">
                                 <img src="../../assets/img/about/abs-sm4.jpg" alt="">
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-xl-6 col-lg-6">
                     <div class="ab-box pl-50 mb-30">
                        <div class="sec-wrapper">
                           <h5>Γνωρίστε μας</h5>
                           <h2 class="section-title">Σχεδιάσουμε διαδικτυακά τη νέα επιχειρηματική ιδέα σας</h2>
                        </div>
                        <div class="ab-item-box mt-40 mb-30">
                           <div class="ab-items fix mb-20">
                              <div class="ab-icon f-left">
                                 <i class="flaticon-honesty"></i>
                              </div>
                              <div class="ab-item-text fix">
                                 <h3 class="ab-item-title">Τι ...</h3>
                                 <p>Αποτελούμε μια ποικιλόμορφη ομάδα με αντικείμενο τον σχεδιασμό ιστοσελίδων, την ανάπτυξη διαδικτυακών εφαρμογών, το ηλεκτρονικό εμπόριο, την ασφάλεια και πολλές άλλες διαδικτυακές υπηρεσίες.</p>
                              </div>
                           </div>
                           <div class="ab-items fix mb-20">
                              <div class="ab-icon f-left">
                                 <i class="flaticon-trustworthy"></i>
                              </div>
                              <div class="ab-item-text fix">
                                 <h3 class="ab-item-title">... και πως</h3>
                                 <p>Δουλεύοντας πάνω από 10 χρόνια  στον χώρο των υπηρεσιών διαδικτύου, έχουμε βοηθήσει πολλές εταιρείες να αναπτυχθούν ψηφιακά μέσω των μοντέρνων και σύγχρονων ιδεών μας. Περνώντας ώρες συζητήσεων, φλιτζανιών καφέ και προγραμματισμού, καταλήγουμε στη σωστή στρατηγική για κάθε εταιρικό μας πελάτη.</p>
                              </div>
                           </div>
                        </div>
                        <div class="ab-btn">
                           <router-link to="/about" class="tp-btn">Περισσότερα για μας</router-link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
</template>

<script>
export default {
    name:'AboutUs'
}
</script>