<template>
        <section class="service-detals pt-120 pb-100 fix">
            <div class="container">
               <div class="row">
                  <div class="col-xxl-8 col-xl-8 col-lg-8">
                        <div class="develop-wrapper">
                           <div class="develop-content">
                              <p>Το wordpress είναι ένα πανίσχυρο σύστημα διαχείρισης περιεχομένου ανοιχτού κώδικα, το οποίο παρέχει τα κατάλληλα εργαλεία ώστε να διαχειρίζεστε την ιστοσελίδα σας εύκολα και με ασφάλεια.</p>
                           </div>
                        </div>

                        <div class="planning-wrapper pt-50">
                           <div class="row">
                              <div class="col-xxl-6 col-xl-6 col-lg-6">
                                    <div class="plan-thumb mb-md-40 mb-xs-40">
                                       <img src="../../assets/img/service/service-img-6-4.jpg" alt="">
                                    </div>
                              </div>
                              <div class="col-xxl-6 col-xl-6 col-lg-6">
                                    <div class="plan-content">
                                       <h4>Χαρακτηριστικά Υπηρεσίας</h4>
                                       <ul>
                                          <li>Lightweight theme</li>
                                          <li>Custom build</li>
                                          <li>SEO Optimized</li>
                                          <li>Manual</li>
                                       </ul>
                                    </div>
                              </div>

                              <div class="col-xxl-12">
                                    <div class="plan-content pr-10 pt-50 pt-xs-30 pt-md-30 pb-15">
                                       <p>Η μετατροπή το HTML/CSS σε wordpress theme γίνεται με τέτοιο τρόπο ώστε να ελαχιστοποιηθεί η χρήση plugins. Αξιοποιόντας δηλαδή το wordpress framework υλοποιούνται σχεδόν όλες οι λειτουργίες που παρουσιάζονται στο HTML/CSS.</p>
                                    </div>
                              </div>
                           </div>
                        </div>
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4">
                        <div class="sidebar-wrap">
                           <div class="widget_categories grey-bg">
                              <h4 class="bs-widget-title pl-20">Υπηρεσίες για Digital Agencies</h4>
                              <ul>
                                    <li><router-link to="/html-to-wordpress">HTML to Wordpress</router-link></li>
                                    <li><router-link to="/psd-to-html">PSD to HTML</router-link></li>
                                    <li><router-link to="/apis-integrations">APIs & Integrations</router-link></li>
                                    <li><router-link to="/woocommerce-opencart">Custom Woocommerce & Opencart Builds</router-link></li>
                                    <li><router-link to="/services">Άλλες υπηρεσίες</router-link></li>
                              </ul>
                           </div>
                           <div class="widget-btn mt-30">
                              <router-link to="/contact" class="tp-btn w-100">Ζητήστε προσφορά</router-link>
                           </div>
                        </div>
                  </div>
               </div>
            </div>
         </section>
</template>

<script>
export default {
    name:'ServicesDetailsArea'
}
</script>