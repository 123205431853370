<template>
    <Header/>
    <HeroSlider/>
    <ServicesArea/>
    <About/>
    <Faq/>
    <Brand border="brand-border" brandBorder=""/>
    <Footer/>
</template>

<script>
import Header from '../../components/Home-two/HomeTwoHeader.vue';
import HeroSlider from '../../components/Home-two/HomeTwoSlider.vue';
import ServicesArea from '../../components/Services/ServicesArea.vue';
import About from '../../components/Home/About.vue';
import Faq from '../../components/Home/Faq.vue';
import Brand from '../../components/common/BrandArea.vue';
import Footer from '../../components/Home/Footer.vue';

export default {
    name:'HomePage',
    components:{
        Header,
        HeroSlider,
        ServicesArea,
        About,
        Faq,
        Brand,
        Footer,
    }
}
</script>