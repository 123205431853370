<template>
     <div class="contact-map">
        <div id="contact-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6139.744740621459!2d19.732899927816757!3d39.69757350489066!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a839e187088213%3A0x6266dff0cb10bd8!2sASK%20IT%20Engineering!5e0!3m2!1sen!2sgr!4v1671707428217!5m2!1sen!2sgr" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name:'CotactMap'
}
</script>