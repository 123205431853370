<template>
  <section class="service-detals pt-120 pb-100 fix">
    <div class="container">
      <div class="row mb-50">
        <div class="col-12">
          <div class="develop-wrapper">
            <div class="develop-content">
              <h3 class="service-title">ASKITE Appointment Management</h3>
              <p><strong>ASKITE-APPOINT-MNG</strong></p>
              <p>
                Στόχος του ASKITE Appointment Management είναι η βελτίωση της
                παραγωγικότητας και εσωτερικής οργάνωσης μικρών ιατρείων, με την
                χρήση μιας απλοποιημένης και φιλικής προς τον χρήστη online
                εφαρμογής. Η εφαρμογή απευθύνεται συγκεκριμένα σε ιατρεία και
                δεν είναι γενικού σκοπού. Βασικός σκοπός είναι η οργάνωση των
                ασθενών, των επισκέψεων αλλά και των πληρωμών που έχουν κάνει.
                Στην ουσία αποτελεί ένα είδος CRM. Ο ασθενής δύναται να
                συμπληρώσει προκαθορισμένα ερωτηματολόγια πριν την επίσκεψη του,
                τα οποία καταχωρούνται αυτόματα την καρτέλα του (ή στην προς
                δημιουργία καρτέλα του).
              </p>
              <p>
                Ταυτόχρονα υπάρχει η δυνατότητα καταχώρησης εξόδων ιατρείου ανα
                κατηγορία και εύκολης αναζήτησης τους.
              </p>
              <p>
                Τέλος, μετά από έγκριση του ασθενή υπάρχει η δυνατότητα
                αποστολής εγγράφων με email.
              </p>
            </div>
          </div>

          <div class="planning-wrapper pt-50">
            <div class="row">
              <div class="col-xxl-5 col-xl-5 col-lg-5">
                <div class="plan-thumb mb-md-40 mb-xs-40">
                  <img
                    src="../../assets/img/service/service-img-6-10.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-xxl-7 col-xl-7 col-lg-7">
                <div class="plan-content">
                  <h4>Χαρακτηριστικά Υπηρεσίας</h4>
                  <ul>
                    <li>
                      Βασισμένο σε Python Django Framework για μέγιστη απόδοση
                      και χρόνους απόκρισης
                    </li>
                    <li>Εγκατάσταση SSL και ασφαλή επικοινωνία</li>
                    <li>
                      Πλήρης συμβατότητα για άτομα με ειδικές ανάγκες (ΑμεΑ)
                      σύμφωνα με το πρωτόκολλο WCAG 2.0.
                    </li>
                    <li>
                      Γρήγορη αναζήτηση και φιλτράρισμα στους πίνακές ασθενών,
                      επισκέψεων, πληρωμών, εξόδων
                    </li>
                    <li>Δυνατότητα προσθήκης γραφημάτων</li>
                    <li>Δυνατότητα προσθήκης πολλαπλών διαχειριστών</li>
                    <li>
                      Δυνατότητα σύνδεσης με website μέσω API για την συμπλήρωση
                      ερωτηματολογίων πριν την επίσκεψη
                    </li>
                    <li>Εξαγωγή δεδομένων σε CSV</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row mt-50 mb-50">
        <div class="col-12">
          <div class="develop-wrapper">
            <div class="develop-content">
              <div class="f-right">
                <h3 class="service-title">ASKITE eShop Light & Standard</h3>
                <p><strong>ASKITE-ESHOP-LIGHT & ASKITE-ESHOP-STD</strong></p>
              </div>
              <p class="clear">
                Σε μια κοινωνία που συνεχώς εξελίσσεται προκύπτει η ανάγκη
                ύπαρξης ηλεκτρονικού καταστήματος ή ηλεκτρονικών πληρωμών από
                όλες τις επιχειρήσεις. Προσφέρεται έτσι η δυνατότητα
                ηλεκτρονικής πώλησης, η αποστολή προϊόντων, οι κάθε τύπου
                ηλεκτρονικές πληρωμές υπηρεσιών αλλά και συνδρομές.
              </p>
              <p>
                Υπάρχει η δυνατότητα σύνδεσης του ηλεκτρονικού καταστήματος με
                τράπεζα της επιλογής σας (Πειραιώς, Eurobank, Alpha Bank, κα.)
                καθώς επίσης και με οποιαδήποτε μεταφορική συνεργάζεστε (ACS,
                Speedex, ΕΛΤΑ Courier κα. )
              </p>
            </div>
          </div>

          <div class="planning-wrapper pt-50">
            <div class="row">
             
              <div class="col-xxl-7 col-xl-7 col-lg-7">
                <div class="plan-content">
                  <h4>Χαρακτηριστικά Υπηρεσίας</h4>
                  <ul>
                    <li>
                      Χρήση συστήματος WordPress Woocommerce ανοιχτού κώδικα με
                      δυνατότητα αναβαθμίσεων / προσθήκης νέων λειτουργιών μέσω
                      εμπορικών plugins (<strong>ASKITE-ESHOP-LIGHT</strong>)
                    </li>
                    <li>
                      Χρήση συστήματος Opencart ανοιχτού κώδικα με δυνατότητα
                      αναβαθμίσεων / προσθήκης νέων λειτουργιών μέσω εμπορικών
                      επεκτάσεων (<strong>ASKITE-ESHOP-STD</strong>)
                    </li>
                    <li>
                      Επιλογή εικαστικών (χρωμάτων & στήσιμων περιεχομένου)
                      σύμφωνα με το αντικείμενο της επιχείρησης
                    </li>
                    <li>
                      Responsive design, ώστε να προσαρμόζεται ανάλογα με τη
                      συσκευή που επισκέπτη
                    </li>
                    <li>
                      Πλήρης συμβατότητα για άτομα με ειδικές ανάγκες (ΑμεΑ)
                      σύμφωνα με το πρωτόκολλο WCAG 2.0.
                    </li>
                    <li>
                      Cross browser testing, ώστε να μην περιέχει σφάλματα σε
                      κοινούς browsers
                    </li>
                    <li>Εγκατάσταση SSL και ασφαλή επικοινωνία</li>
                    <li>OnSite SEO βελτιστοποίηση</li>
                    <li>
                      Χρήση και ρύθμιση συστήματος Wordfence για την αποφυγή
                      κυβερνοεπιθέσεων
                    </li>
                    <li>
                      Two Factor Authentication. Σύνδεση με κωδικό χρήστη και
                      κωδικό εφαρμογής στο κινητό του διαχειριστή
                    </li>
                    <li>Μαζική εισαγωγή προϊόντων από CSV</li>
                    <li>Αναζήτηση προϊόντων με χρήση πολλαπλών φίλτρων</li>
                    <li>Σύνδεση με ελληνικές τράπεζες</li>
                    <li>Σύνδεση με μεταφορικές εταιρείες</li>
                    <li>Λογαριασμός χρήστη και ιστορικό παραγγελιών</li>
                    <li>Λίστα αγαπημένων</li>
                    <li>Αναφορές πωλήσεων</li>
                    <li>
                      Πόντοι ανταμοιβής (<strong>ASKITE-ESHOP-STD</strong>)
                    </li>
                    <li>
                      Υποστήριξη πολλαπλών νομισμάτων
                      (<strong>ASKITE-ESHOP-STD</strong>)
                    </li>
                    <li>
                      Πελάτες λιανική & χονδρικής
                      (<strong>ASKITE-ESHOP-STD</strong>)
                    </li>
                  </ul>
                </div>
              </div>
               <div class="col-xxl-5 col-xl-5 col-lg-5">
                <div class="plan-thumb mb-md-40 mb-xs-40 f-right">
                  <img
                    src="../../assets/img/service/service-img-6-2.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row mt-50">
        <div class="col-12">
          <div class="develop-wrapper">
            <div class="develop-content">
              <h3 class="service-title">ASKITE Digital Marketing</h3>
              <p><strong>ASKITE-DIGI-MARKETING</strong></p>
              <p>
                Η προώθηση στις μηχανές αναζήτησης και πιο συγκεκριμένα στην
                Google είναι πλέον αναγκαία για την κατάλληλη προώθηση μιας
                επιχείρησης. Επίσης, τα Social Media και πιο συγκεκριμένα η
                χρήση του Facebook έχουν εδραιωθεί στην καθημερινότητα μας. Η
                ομάδα marketing αναλαμβάνει την δημιουργία στοχευμένων ενεργειών
                (καμπάνιες) μέσω του Google Ads αλλά και του Facebook.
              </p>
            </div>
          </div>

          <div class="planning-wrapper pt-50">
            <div class="row">
              <div class="col-xxl-5 col-xl-5 col-lg-5">
                <div class="plan-thumb mb-md-40 mb-xs-40">
                  <img
                    src="../../assets/img/service/service-img-6-9.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-xxl-7 col-xl-7 col-lg-7">
                <div class="plan-content">
                  <h4>Χαρακτηριστικά Υπηρεσίας</h4>
                  <ul>
                    <li>
                      Εγκατάσταση εργαλείου Google Tag Manager στο σύστημα και
                      εγκατάσταση προγραμματισμένων ενεργειών (events)
                    </li>
                    <li>
                      Διασύνδεση με το σύστημα της Google (Google Ads) και
                      εγκατάσταση αυτοματισμών για παρακολούθηση στατιστικών
                      πωλήσεων καθώς και επαναστόχευση πελατών (Remarketing)
                    </li>
                    <li>
                      Διασύνδεση με το σύστημα της Meta (Facebook Ads) και
                      εγκατάσταση αυτοματισμών μέσω του Facebook Pixel (CAPI)
                      για παρακολούθηση στατιστικών πωλήσεων καθώς και
                      επαναστόχευση πελατών (Remarketing)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "psifiakosMetasximatismos",
};
</script>