<template>
    <Header/>
    <Breadcrumb title="Επικοινωνία" />
    <ContactArea/>
    <ContactMap/>
    <Footer/>
</template>

<script>
import Header from '../../components/Home/Header.vue';
import Breadcrumb from '../../components/common/Breadcrumb.vue';
import ContactArea from '../../components/Contact/ContactArea.vue';
import ContactMap from '../../components/Contact/ContactMap.vue';
import Footer from '../../components/Home/Footer.vue';

export default {
    name:'ContactPage',
    components:{
        Header,
        Breadcrumb,
        ContactArea,
        ContactMap,
        Footer,
    }
}
</script>