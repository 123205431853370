<template>
  <header class="header-light">
    <div class="top-bar d-none d-md-block pt-15 pb-15">
      <div class="container">
        <div class="row d-flex align-items-center">
          <div class="col-xl-8 col-lg-8 col-md-7">
            <div class="header-info">
              <span class="header-address d-none d-lg-inline"
                ><i class="fa fa-map-marker-alt"></i>
                <a
                  target="_blank"
                  href="https://www.google.com/maps/place/ASK+IT+Engineering/@39.6996166,19.7277179,17.05z/data=!4m8!1m2!2m1!1saskite+!3m4!1s0x14a839e187088213:0x6266dff0cb10bd8!8m2!3d39.6989598!4d19.7265461"
                  >Αλειμματάδες 49083 Κέρκυρα</a
                >
              </span>
              <span class="header-phone"
                ><i class="fas fa-phone"></i>
                <a href="callto:+302663071953">+30 2663-071-953</a></span
              >
              <span class="header-email d-none d-xl-inline"
                ><i class="fas fa-envelope"></i>
                <a href="mailto:hello@askite.com">hello@askite.com</a></span
              >
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-5 text-end">
            <div class="header-social">
              <a
                href="https://www.facebook.com/askitengineering/"
                target="_blank"
                ><i class="fab fa-facebook"></i
              ></a>
              <a
                id="researchgate"
                href="https://www.researchgate.net/profile/Alexandros-Katsaros-4"
                target="_blank"
                ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    d="M0 32v448h448V32H0zm262.2 334.4c-6.6 3-33.2 6-50-14.2-9.2-10.6-25.3-33.3-42.2-63.6-8.9 0-14.7 0-21.4-.6v46.4c0 23.5 6 21.2 25.8 23.9v8.1c-6.9-.3-23.1-.8-35.6-.8-13.1 0-26.1.6-33.6.8v-8.1c15.5-2.9 22-1.3 22-23.9V225c0-22.6-6.4-21-22-23.9V193c25.8 1 53.1-.6 70.9-.6 31.7 0 55.9 14.4 55.9 45.6 0 21.1-16.7 42.2-39.2 47.5 13.6 24.2 30 45.6 42.2 58.9 7.2 7.8 17.2 14.7 27.2 14.7v7.3zm22.9-135c-23.3 0-32.2-15.7-32.2-32.2V167c0-12.2 8.8-30.4 34-30.4s30.4 17.9 30.4 17.9l-10.7 7.2s-5.5-12.5-19.7-12.5c-7.9 0-19.7 7.3-19.7 19.7v26.8c0 13.4 6.6 23.3 17.9 23.3 14.1 0 21.5-10.9 21.5-26.8h-17.9v-10.7h30.4c0 20.5 4.7 49.9-34 49.9zm-116.5 44.7c-9.4 0-13.6-.3-20-.8v-69.7c6.4-.6 15-.6 22.5-.6 23.3 0 37.2 12.2 37.2 34.5 0 21.9-15 36.6-39.7 36.6z"
                  /></svg
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="header-sticky"
      :class="`${
        isSticky
          ? 'header-area header-pad-2 sticky-2 sticky'
          : 'header-area header-pad-2 sticky-2'
      }`"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-2 col-lg-2 col-md-6 col-6">
            <div class="logo logo-border">
              <router-link to="/">
                <img src="../../assets/img/logo/logo-dark.svg" alt="logo" />
              </router-link>
            </div>
          </div>
          <div
            class="col-xl-10 col-lg-10 col-md-6 col-6 d-flex justify-content-end"
          >
            <div class="main-menu text-center">
              <nav id="mobile-menu">
                <ul>
                  <li>
                    <router-link to="/landing"> Αρχική</router-link>
                  </li>
                  <li>
                    <router-link to="/about">Ποιοι Είμαστε</router-link>
                  </li>
                  <li>
                    <router-link to="/services">Υπηρεσίες</router-link>
                    <ul class="sub-menu">
                      <li>
                        <router-link to="/kataskevi-istoselidon"
                          >Κατασκευή Ιστοσελίδων</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/ecommerce-online-payments"
                          >Ecommerce / Online Payments</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/veltistopoihsi-istoselidas"
                          >Βελτιστοποίηση Ιστοσελίδων</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/ypostorixi-istoselidon"
                          >Υποστήριξη Ιστοσελίδων</router-link
                        >
                      </li>
                    </ul>
                  </li>
                  <li>
                    <router-link to="/portfolio">Έργα</router-link>
                  </li>
                  <li>
                    <router-link to="/"
                      >Ψηφιακός Μετασχηματισμός</router-link
                    >
                  </li>
                </ul>
              </nav>
            </div>
            <div
              class="header-right-info d-flex align-items-center justify-content-end"
            >
              <div class="sidebar__menu d-lg-none">
                <div
                  @click="menuOption.menuShow = !menuOption.menuShow"
                  class="sidebar-toggle-btn sidebar-toggle-btn-2 ml-30"
                  id="sidebar-toggle"
                >
                  <span class="line"></span>
                  <span class="line"></span>
                  <span class="line"></span>
                </div>
              </div>
              <div class="header-btn d-none d-lg-block">
                <router-link to="/contact" class="tp-btn"
                  >Επικοινωνία</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <!-- Mobile Menu Start -->
  <div class="fix">
    <div
      class="side-mobile-menu"
      :class="[menuOption.menuShow === true ? 'active' : '']"
    >
      <div class="sidebar__wrapper">
        <div
          @click="menuOption.menuShow = !menuOption.menuShow"
          class="sidebar__close"
        >
          <button class="sidebar__close-btn" id="sidebar__close-btn">
            <i class="fal fa-times"></i>
          </button>
        </div>
        <div class="sidebar__content">
          <div class="logo mb-40">
            <router-link to="/">
              <img src="../../assets/img/logo/logo-dark.svg" alt="logo" />
            </router-link>
          </div>
          <div class="mobile-menu fix">
            <div class="side-info-content sidebar-menu mm-menu">
              <ul>
                <li>
                  <router-link to="/landing">Αρχικη</router-link>
                </li>

                <li>
                  <router-link to="/about">Ποιοι Ειμαστε</router-link>
                </li>

                <li>
                  <router-link to="/portfolio">Εργα</router-link>
                </li>

                <li
                  class="menu-item-has-children has-droupdown"
                  v-bind:class="[
                    menuOption.homeDropdown === true ? 'active' : '',
                  ]"
                >
                  <a
                    v-on:click="
                      menuOption.homeDropdown = !menuOption.homeDropdown
                    "
                    >Υπηρεσιες</a
                  >
                  <ul
                    class="sub-menu"
                    v-bind:class="[
                      menuOption.homeDropdown === true ? 'active' : '',
                    ]"
                  >
                    <li>
                      <router-link to="/kataskevi-istoselidon"
                        >Κατασκευή Ιστοσελίδων</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/ecommerce-online-payments"
                        >Ecommerce / Online Payments</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/veltistopoihsi-istoselidas"
                        >Βελτιστοποίηση Ιστοσελίδων</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/ypostorixi-istoselidon"
                        >Υποστήριξη Ιστοσελίδων</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/services#digital-agencies"
                        >Υπηρεσίες για Digital Agencies</router-link
                      >
                    </li>
                  </ul>
                </li>

                <li>
                  <router-link to="/contact" class="border-0"
                    >Επικοινωνια</router-link
                  >
                </li>
                <li>
                  <router-link to="/" class="border-0"
                    >Ψηφιακός Μετασχηματισμός</router-link
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="sidebar__contact mt-30 mb-45">
            <ul>
              <li>
                <div class="icon theme-color">
                  <i class="fal fa-envelope"></i>
                </div>
                <div class="text theme-color">
                  <span
                    ><a href="mailto:hello@askite.com"
                      >hello@askite.com</a
                    ></span
                  >
                </div>
              </li>

              <li>
                <div class="icon theme-color">
                  <i class="fas fa-phone-volume"></i>
                </div>
                <div class="text theme-color">
                  <span><a href="tel:+302663071953">+30 2663-071-953</a></span>
                </div>
              </li>

              <li>
                <div class="icon">
                  <i class="fal fa-map-marker-alt"></i>
                </div>
                <div class="text">
                  <a
                    target="_blank"
                    href="https://www.google.com/maps/place/ASK+IT+Engineering/@39.6996166,19.7277179,17.05z/data=!4m8!1m2!2m1!1saskite+!3m4!1s0x14a839e187088213:0x6266dff0cb10bd8!8m2!3d39.6989598!4d19.7265461"
                    >Αλειμματάδες 49083 Κέρκυρα</a
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="body-overlay transition-3"
    :class="[menuOption.menuShow === true ? 'opened' : '']"
  ></div>
  <!-- Mobile Menu End -->
</template>

<script>
export default {
  name: "HomeTwoHeader",
  data() {
    return {
      isSticky: false,
      searchPopup: false,
      menuOption: {
        menuShow: false,
        menuSearch: false,
        homeDropdown: false,
        serviceDropdown: false,
        pagesDropDown: false,
        blogDropdown: false,
        aboutDropdown: false,
      },
    };
  },
  methods: {
    handleSticky() {
      if (window.scrollY > 80) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    handleSearch() {
      this.searchPopup = true;
    },
    handleClose() {
      this.searchPopup = false;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleSticky);
  },
};
</script>