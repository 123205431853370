<template>
  <section class="service-detals pt-120 pb-100 fix">
    <div class="container">
      <div class="row">
        <div class="col-xxl-8 col-xl-8 col-lg-8">
          <div class="develop-wrapper">
            <div class="develop-content">
              <p>
                Εαν έχετε ανάγκη τεχνικής ή συμβουλευτικής υποστήριξης για την
                ιστοσελίδα ή το ηλεκτρονικό σας κατάστημα τότε μπορείτε να
                επιλέξετε μεταξύ των διαθέσιμων πακέτων υποστήριξης που
                διαθέτουμε. Φροντίζουμε την ενημέρωση της πλατφόρμας της
                ιστοσελίδας σας, τη διατήρηση αντιγράφων ασφαλείας, την ασφάλεια
                και διαθεσιμότητα των δεδομένων σας, καθώς και πλήθος άλλων
                εργασιών ώστε να αφιερώνετε χρόνο μόνο στις πωλήσεις σας
              </p>
            </div>
          </div>

          <div
            class="choose-right aos-init aos-animate"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    Πως μπορώ να έχω επαγγελματικό email (πχ. info@mydomain.gr)
                    και πόσο κοστίζει;
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                  style=""
                >
                  <div class="accordion-body text-white">
                    <p>
                      Μπορούμε να δημιουργήσουμε για εσάς επαγγελματικό email
                      στη Google ή στην Microsoft. Με αυτόν τον τρόπο θα είστε
                      σίγουροι ότι δεν θα χαθεί κάποιο μήνυμα σας, θα έχετε
                      αρκετό χώρο για τα μηνύματα και τα συνημμένα σας και
                      επίσης δεν θα λαμβάνετε πλήθος spam γιατί παρέχονται
                      αποδοτικοί μηχανισμοί για να τα φιλτράρουν. To κόστος για
                      ένα email της Google είναι 5,60€ και της Microsoft 5,1 €.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Τι είναι το Web Hosting και πόσο κοστίζει;
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                  style=""
                >
                  <div class="accordion-body text-white">
                    <p>
                      Η φιλοξενία της ιστοσελίδας (web hosting) αφορά στους
                      πόρους που χρησιμοποιούνται από την εκάστοτε εφαρμογή στο
                      υπολογιστικό νέφος (cloud). Για παράδειγμα, ένα
                      ηλεκτρονικό κατάστημα χρησιμοποιεί πολύ περισσότερους
                      πόρους για να λειτουργεί από ότι μια ιστοσελίδα εταιρικής
                      παρουσίασης. Το κόστος της φιλοξενίας της ιστοσελίδας
                      είναι περίπου 150€ ανα έτος για ιστοσελίδες με χαμηλή
                      κατανάλωση πόρων.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Μπορώ να διαχειρίζομαι μόνος/μόνη το περιεχόμενο της
                    ιστοσελίδας;
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                  style=""
                >
                  <div class="accordion-body text-white">
                    <p>
                      Μετά από λίγες ώρες εκμάθησης, που προσφέρουμε δωρεάν,
                      είστε σε θέση να κάνετε σύνδεση με κωδικούς και να
                      διαχειρίζεστε το περιεχόμενο των υποσελίδων σας. Βέβαια,
                      αν το επιθυμείτε μπορούμε να αναλάβουμε εμείς την
                      διαχείριση.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <router-link to="/services#faqs-area" class="tp-btn float-end"
                >Έχετε περισσότερες απορίες;</router-link
              >
            </div>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4">
          <div class="sidebar-wrap">
            <div class="widget_categories grey-bg">
              <h4 class="bs-widget-title pl-20">Υπηρεσίες</h4>
              <ul>
                <li>
                  <router-link to="/kataskevi-istoselidon"
                    >Κατασκευή Ιστοσελίδων</router-link
                  >
                </li>
                <li>
                  <router-link to="/ecommerce-online-payments"
                    >Ecommerce / Online Payments</router-link
                  >
                </li>
                <li>
                  <router-link to="/veltistopoihsi-istoselidas"
                    >Βελτιστοποίηση Ιστοσελίδων</router-link
                  >
                </li>
                <li>
                  <router-link to="/ypostorixi-istoselidon"
                    >Υποστήριξη Ιστοσελίδων</router-link
                  >
                </li>
                <li>
                  <router-link to="/services#digital-agencies"
                    >για Digital Agencies</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="widget-btn mt-30">
              <router-link to="/contact" class="tp-btn w-100"
                >Ζητήστε προσφορά</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ServicesDetailsArea",
};
</script>