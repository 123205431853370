<template>
  <div class="container">
      <div class="row">
        <div v-for="(portfolioItem,imageIndex) in portfolioData" :key="portfolioItem.id" class="col-lg-4 col-md-6">
        <div class="tportfolio mb-30">
            <div class="tportfolio__img">
                <button class="popup-image">
                    <img :src="typeof portfolioItem.image === 'string' ? portfolioItem.image 
                    : portfolioItem.image" />
                </button>
            </div>
            <div class="tportfolio__text">
                <h3 class="tportfolio-title">
                  {{ portfolioItem.title }}
                </h3>
                <h4>{{ portfolioItem.subtitle }}</h4>
                <div class="portfolio-plus">
                    <button v-on:click="showImg(imageIndex)">
                    <i class="fal fa-plus"></i>
                    </button>
                </div>
            </div>
        </div>
         
    </div>
      </div>
       <vue-easy-lightbox
            :visible="visible"
            :imgs="portfolioData.map(img => img.image)"
            :index="index"
            @hide="handleHide"
        ></vue-easy-lightbox>
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
    name:'GalleryOne',
    components: {
    VueEasyLightbox,
   },
   data () {
       return {
        visible: false,
        index: 0,
        portfolioData:[
           {
               id:'5',
               image:require(`@/assets/img/portfolio/odysseus_project.jpg`),
               title:'Odysseus Yachting Holidays',
               subtitle:''
            },
            {
               id:'6',
               image:require(`@/assets/img/portfolio/fakiolassp_project.jpg`),
               title:'Fakiolas Wood Collection',
               subtitle:''
            },
            {
               id:'8',
               image:require(`@/assets/img/portfolio/apari_project.jpg`),
               title:'Α Pa-Ri Event Boutique',
               subtitle:'Διοργάνωση Εκδηλώσεων'
            },
            {
               id:'9',
               image:require(`@/assets/img/portfolio/corfumedica_project.jpg`),
               title:'CorfuMedica',
               subtitle:'Medical Services'
            },
            {
               id:'12',
               image:require(`@/assets/img/portfolio/skiclub105_project.jpg`),
               title:'Skiclub105',
               subtitle:''
            },
            {
               id:'13',
               image:require(`@/assets/img/portfolio/smartcorfucarhire_project.jpg`),
               title:'A Smart Choice Car Hire',
               subtitle:''
            },
         ]
       }
   },
    methods: {
      showImg(index) {
        this.index = index
        this.visible = true
      },
      handleHide() {
        this.visible = false
      }
    }

}
</script>