import { createWebHistory, createRouter } from "vue-router";
import Home from '../pages/Home/Home.vue';
import About from '../pages/About/About.vue';
import Services from '../pages/Services/Services.vue';
import ServicesDetails1 from '../pages/ServicesDetails/ServicesDetails1.vue';
import ServicesDetails2 from '../pages/ServicesDetails/ServicesDetails2.vue';
import ServicesDetails3 from '../pages/ServicesDetails/ServicesDetails3.vue';
import ServicesDetails4 from '../pages/ServicesDetails/ServicesDetails4.vue';
import ServicesDetails5 from '../pages/ServicesDetails/ServicesDetails5.vue';
import ServicesDetails6 from '../pages/ServicesDetails/ServicesDetails6.vue';
import ServicesDetails7 from '../pages/ServicesDetails/ServicesDetails7.vue';
import ServicesDetails8 from '../pages/ServicesDetails/ServicesDetails8.vue';
import Portfolio from '../pages/Portfolio/Portfolio.vue';
import ErrorPage from '../pages/404/404.vue';
import Contact from '../pages/Contact/Contact.vue';
import psifiakosMetasximatismos from '../pages/Other/psifiakosMetasximatismos.vue';

const router = createRouter({
    mode: 'history',
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else if (to.hash) {
            return {
                el: to.hash
            }
        } else {
            return { top: 0 }
        }
    },
    routes: [
        {
            path: '/landing',
            component: Home,
            meta: {
                title: 'ASK IT Engineering - Κατασκευή Ιστοσελίδων, eShops, Εφαρμογών',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Αποτελούμε μια ποικιλόμορφη ομάδα με αντικείμενο τον σχεδιασμό ιστοσελίδων, την ανάπτυξη διαδικτυακών εφαρμογών, το ηλεκτρονικό εμπόριο, την ασφάλεια και πολλές άλλες διαδικτυακές υπηρεσίες.'
                    },
                    {
                        name: 'og:description',
                        content: 'Αποτελούμε μια ποικιλόμορφη ομάδα με αντικείμενο τον σχεδιασμό ιστοσελίδων, την ανάπτυξη διαδικτυακών εφαρμογών, το ηλεκτρονικό εμπόριο, την ασφάλεια και πολλές άλλες διαδικτυακές υπηρεσίες.'
                    }
                ]
            }
        },
        {
            path: '/',
            component: psifiakosMetasximatismos,
            meta: {
                title: 'Ψηφιακός Μετασχηματισμός - ASK IT Engineering',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Εξαργυρώστε το Voucher σας επιλέγοντας μια ένα από τα προϊόντα μας.'
                    },
                    {
                        name: 'og:description',
                        content: 'Εξαργυρώστε το Voucher σας επιλέγοντας μια ένα από τα προϊόντα μας.'
                    }
                ]
            }
        },
        {
            path: '/about',
            component: About,
            meta: {
                title: 'Ποιοι Είμαστε - ASK IT Engineering',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Η ASK IT Engineering με έδρα την Κέρκυρα δραστηριοποιείται στον σχεδιασμό & ανάπτυξη εφαρμογών πληροφορικής. Προσφέρει ποιοτικές, αξιόπιστες λύσεις σε λογικές τιμές.'
                    },
                    {
                        name: 'og:description',
                        content: 'Η ASK IT Engineering με έδρα την Κέρκυρα δραστηριοποιείται στον σχεδιασμό & ανάπτυξη εφαρμογών πληροφορικής. Προσφέρει ποιοτικές, αξιόπιστες λύσεις σε λογικές τιμές.'
                    }
                ]
            }
        },
        {
            path: '/services',
            component: Services,
            meta: {
                title: 'Υπηρεσίες - ASK IT Engineering',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Δουλεύοντας πάνω από 10 χρόνια στον χώρο των υπηρεσιών διαδικτύου, έχουμε βοηθήσει πολλές εταιρείες να αναπτυχθούν ψηφιακά μέσω των μοντέρνων και σύγχρονων ιδεών μας.'
                    },
                    {
                        name: 'og:description',
                        content: 'Δουλεύοντας πάνω από 10 χρόνια στον χώρο των υπηρεσιών διαδικτύου, έχουμε βοηθήσει πολλές εταιρείες να αναπτυχθούν ψηφιακά μέσω των μοντέρνων και σύγχρονων ιδεών μας.'
                    }
                ]
            }
        },
        {
            path: '/kataskevi-istoselidon',
            component: ServicesDetails1,
            meta: {
                title: 'Κατασκευή Ιστοσελίδων - ASK IT Engineering',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Ανεξάρτητα από τον κλάδο που δραστηριοποιείται η επιχείρηση σας μπορούμε να σας προσφέρουμε ένα μοναδικό μέσο επικοινωνίας με τους πελάτες σας, ώστε να βρίσκουν πάντα αυτό που ζητούν χωρίς κόπο.'
                    },
                    {
                        name: 'og:description',
                        content: 'Ανεξάρτητα από τον κλάδο που δραστηριοποιείται η επιχείρηση σας μπορούμε να σας προσφέρουμε ένα μοναδικό μέσο επικοινωνίας με τους πελάτες σας, ώστε να βρίσκουν πάντα αυτό που ζητούν χωρίς κόπο.'
                    }
                ]
            }
        },
        {
            path: '/ecommerce-online-payments',
            component: ServicesDetails2,
            meta: {
                title: 'eCommerce, Online Payments - ASK IT Engineering',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Σε μια κοινωνία που συνεχώς εξελίσσεται προκύπτει η ανάγκη ύπαρξης ηλεκτρονικού καταστήματος ή ηλεκτρονικών πληρωμών από όλες τις επιχειρήσεις.'
                    },
                    {
                        name: 'og:description',
                        content: 'Σε μια κοινωνία που συνεχώς εξελίσσεται προκύπτει η ανάγκη ύπαρξης ηλεκτρονικού καταστήματος ή ηλεκτρονικών πληρωμών από όλες τις επιχειρήσεις.'
                    }
                ]
            }
        },
        {
            path: '/veltistopoihsi-istoselidas',
            component: ServicesDetails3,
            meta: {
                title: 'Βελτιστοποίηση Ιστοσελίδων - ASK IT Engineering',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Με τον όρο βελτιστοποίηση ιστοσελίδας εννοούμε όλες τις διαδικασίες που απαιτούνται ώστε να μειωθεί ο χρόνος απόκρισης μιας σελίδας καθώς και ο χρόνος αλληλεπίδρασης με τον χρήστη.'
                    },
                    {
                        name: 'og:description',
                        content: 'Με τον όρο βελτιστοποίηση ιστοσελίδας εννοούμε όλες τις διαδικασίες που απαιτούνται ώστε να μειωθεί ο χρόνος απόκρισης μιας σελίδας καθώς και ο χρόνος αλληλεπίδρασης με τον χρήστη.'
                    }
                ]
            }
        },
        {
            path: '/ypostorixi-istoselidon',
            component: ServicesDetails4,
            meta: {
                title: 'Υποστήριξη Ιστοσελίδων - ASK IT Engineering',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Εαν έχετε ανάγκη τεχνικής ή συμβουλευτικής υποστήριξης για την ιστοσελίδα ή το ηλεκτρονικό σας κατάστημα τότε μπορείτε να επιλέξετε μεταξύ των διαθέσιμων πακέτων υποστήριξης που διαθέτουμε.'
                    },
                    {
                        name: 'og:description',
                        content: 'Εαν έχετε ανάγκη τεχνικής ή συμβουλευτικής υποστήριξης για την ιστοσελίδα ή το ηλεκτρονικό σας κατάστημα τότε μπορείτε να επιλέξετε μεταξύ των διαθέσιμων πακέτων υποστήριξης που διαθέτουμε.'
                    }
                ]
            }
        },
        {
            path: '/html-to-wordpress',
            component: ServicesDetails5,
            meta: {
                title: 'HTML to Wordpress - ASK IT Engineering',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Η μετατροπή το HTML/CSS σε wordpress theme γίνεται με τέτοιο τρόπο ώστε να ελαχιστοποιηθεί η χρήση plugins.'
                    },
                    {
                        name: 'og:description',
                        content: 'Η μετατροπή το HTML/CSS σε wordpress theme γίνεται με τέτοιο τρόπο ώστε να ελαχιστοποιηθεί η χρήση plugins.'
                    }
                ]
            }
        },
        {
            path: '/psd-to-html',
            component: ServicesDetails6,
            meta: {
                title: 'PSD to HTML - ASK IT Engineering',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Παραλαμβάνουμε PSD, Sketch, Adobe Illustrator, Zeplin, Invision αρχεία εικαστικών από τον γραφίστα σας και τα μετατρέπουμε σε μια πλήρως λειτουργική ιστοσελίδα.'
                    },
                    {
                        name: 'og:description',
                        content: 'Παραλαμβάνουμε PSD, Sketch, Adobe Illustrator, Zeplin, Invision αρχεία εικαστικών από τον γραφίστα σας και τα μετατρέπουμε σε μια πλήρως λειτουργική ιστοσελίδα.'
                    }
                ]
            }
        },
        {
            path: '/apis-integrations',
            component: ServicesDetails7,
            meta: {
                title: 'APIs & Integrations - ASK IT Engineering',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Μπορούμε αν διασυνδέσουμε εφαρμογές σας με υπηρεσίες όπως Google Drive, Dropbox, Paypal κάνοντας χρήση του API που παρέχονται από αυτές τις υπηρεσίες.'
                    },
                    {
                        name: 'og:description',
                        content: 'Μπορούμε αν διασυνδέσουμε εφαρμογές σας με υπηρεσίες όπως Google Drive, Dropbox, Paypal κάνοντας χρήση του API που παρέχονται από αυτές τις υπηρεσίες.'
                    }
                ]
            }
        },
        {
            path: '/woocommerce-opencart',
            component: ServicesDetails8,
            meta: {
                title: 'Woocommerce & Opencart - ASK IT Engineering',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Χιλιάδες νέα woocommerce shops δημιουργούνται ετησίως, καθώς το woocommerce είναι μια αξιόπιστη και χρηστική λύση για ηλεκτρονικά καταστήματα που αριθμούν από 1 ως μερικές εκατοντάδες προϊόντα.'
                    },
                    {
                        name: 'og:description',
                        content: 'Χιλιάδες νέα woocommerce shops δημιουργούνται ετησίως, καθώς το woocommerce είναι μια αξιόπιστη και χρηστική λύση για ηλεκτρονικά καταστήματα που αριθμούν από 1 ως μερικές εκατοντάδες προϊόντα.'
                    }
                ]
            }
        },
        {
            path: '/portfolio',
            component: Portfolio,
            meta: {
                title: 'Portfolio - ASK IT Engineering',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Περνώντας ώρες συζητήσεων, φλιτζανιών καφέ και προγραμματισμού, καταλήγουμε στη σωστή στρατηγική για κάθε εταιρικό μας πελάτη.'
                    },
                    {
                        name: 'og:description',
                        content: 'Περνώντας ώρες συζητήσεων, φλιτζανιών καφέ και προγραμματισμού, καταλήγουμε στη σωστή στρατηγική για κάθε εταιρικό μας πελάτη.'
                    }
                ]
            }
        },
        {
            path: '/contact',
            component: Contact,
            meta: {
                title: 'Επικοινωνία - ASK IT Engineering',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Επικοινωνήσετε μαζί μας για οποιαδήποτε απορία σας σχετικά με τον τρόπο, το κόστος & τον χρόνο.'
                    },
                    {
                        name: 'og:description',
                        content: 'Επικοινωνήσετε μαζί μας για οποιαδήποτε απορία σας σχετικά με τον τρόπο, το κόστος & τον χρόνο.'
                    }
                ]
            }
        },
        {
            path: '/:pathMatch(.*)*',
            component: ErrorPage,
        }
    ]
})

router.beforeEach((to, from, next) => {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    if (!nearestWithMeta) return next();

    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    }).forEach(tag => document.head.appendChild(tag));

    next();
    window.scrollTo(0, 0)
});

export default router