<template>
    <Header/>
    <Breadcrumb title="Ποιοι Ειμαστε" />
    <HomeTwoAbout/>
    <Counter/>
    <Footer/>
</template>

<script>
import Header from '../../components/Home/Header.vue';
import Breadcrumb from '../../components/common/Breadcrumb.vue';
import HomeTwoAbout from '../../components/Home-two/HomeTwoAbout.vue';
import Counter from '../../components/Home/Counter.vue';
import Footer from '../../components/Home/Footer.vue';

export default {
    name:'AboutPage',
    components:{
        Header,
        Breadcrumb,
        HomeTwoAbout,
        Counter,
        Footer,
    }
}
</script>