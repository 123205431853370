<template>
    <Header/>
    <Breadcrumb title="Υπηρεσιες" />
    <ServicesArea class="no-services-btn"/>
    <Services/>
    <FaqArea/>
    <Footer/>
</template>

<script>
import Header from '../../components/Home/Header.vue';
import Breadcrumb from '../../components/common/Breadcrumb.vue';
import ServicesArea from '../../components/Services/ServicesArea_inner.vue';
import Services from '../../components/Home/Services.vue';
import FaqArea from '../../components/Faq/FaqArea.vue';
import Footer from '../../components/Home/Footer.vue';

export default {
    name:'ServicesPage',
    components:{
        Header,
        Breadcrumb,
        ServicesArea,
        Services,
        FaqArea,
        Footer,
    }
}
</script>