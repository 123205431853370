<template>
        <section class="service-detals pt-120 pb-100 fix">
            <div class="container">
               <div class="row">
                  <div class="col-xxl-8 col-xl-8 col-lg-8">
                        <div class="develop-wrapper">
                           <div class="develop-content">
                              <p>Με τον όρο βελτιστοποίηση ιστοσελίδας εννοούμε όλες τις διαδικασίες που απαιτούνται ώστε να μειωθεί ο χρόνος απόκρισης μιας σελίδας καθώς και ο χρόνος αλληλεπίδρασης με τον χρήστη. Η βελτιστοποίηση της ιστοσελίδας μπορεί να παίξει σημαντικό ρόλο στις πωλήσεις του καταστήματος σας (ηλεκτρονικού & φυσικού). Επίσης, οι μηχανές αναζήτησης λαμβάνουν υπόψη τους παραπάνω χρόνους στην κατάταξη των αποτελεσμάτων αναζήτησης.</p>
                           </div>
                        </div>

                        <div class="planning-wrapper pt-50 pb-50">
                           <div class="row">
                              <div class="col-xxl-6 col-xl-6 col-lg-6">
                                    <div class="plan-thumb mb-md-40 mb-xs-40">
                                       <img src="../../assets/img/service/service-img-6-3.jpg" alt="">
                                    </div>
                              </div>
                              <div class="col-xxl-6 col-xl-6 col-lg-6">
                                    <div class="plan-content">
                                       <h4>Εργαλεία ελέγχου ιστοσελίδων</h4>
                                       <ul>
                                          <li>Google Insights</li>
                                          <li>GtMetrix</li>
                                          <li>Semrush</li>
                                       </ul>
                                    </div>
                              </div>
                           </div>
                        </div>

                         <div class="choose-right aos-init aos-animate" data-aos="fade-left" data-aos-duration="1000">
                           <div class="accordion" id="accordionExample">
                              <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                       <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                          Πόσο κοστίζει μια ιστοσελίδα;
                                       </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style="">
                                       <div class="accordion-body">
                                          <p>Το κόστος μιας ιστοσελίδας δεν είναι σταθερό, αλλά εξαρτάται από πολλούς παράγοντες. Το κόστος υπολογίζεται μετά από την ανάλυση του έργου και την καταγραφή όλων των λειτουργικών απαιτήσεων. Η ύπαρξη κειμένων και φωτογραφιών είναι άλλος ένα παράγοντας που επηρεάζει το κόστος. Αν δεν υπάρχουν μπορούμε να αναλάβουμε είτε εμείς την επιμέλεια των κειμένων είτε εσείς. Η ύπαρξη ή όχι online πληρωμών, επίσης επηρεάζει το κόστος.</p>
                                       </div>
                                    </div>
                              </div>
                              <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo">
                                       <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                          Πόσο χρόνο χρειάζεται για να ολοκληρωθεί;
                                       </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style="">
                                       <div class="accordion-body">
                                          <p>Η παραπάνω διαδικασία, η οποία καθορίζει το κόστος, είναι η ίδια με την οποία γίνεται και η εκτίμηση χρόνου υλοποίησης. Ο χρόνος ποικίλλει συνήθως από 1 ως και 6 μήνες.</p>
                                       </div>
                                    </div>
                              </div>
                              <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                       <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                          Μπορώ να διαχειρίζομαι μόνος/μόνη το περιεχόμενο της ιστοσελίδας;
                                       </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" style="">
                                       <div class="accordion-body">
                                          <p>Μετά από λίγες ώρες εκμάθησης, που προσφέρουμε δωρεάν, είστε σε θέση να κάνετε σύνδεση με κωδικούς και να διαχειρίζεστε το περιεχόμενο των υποσελίδων σας. Βέβαια, αν το επιθυμείτε μπορούμε να αναλάβουμε εμείς την διαχείριση.</p>
                                       </div>
                                    </div>
                              </div>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-12">
                              <router-link to="/services#faqs-area" class="tp-btn float-end">Έχετε περισσότερες απορίες;</router-link>
                           </div>
                        </div>
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4">
                        <div class="sidebar-wrap">
                           <div class="widget_categories grey-bg">
                              <h4 class="bs-widget-title pl-20">Υπηρεσίες</h4>
                              <ul>
                                    <li><router-link to="/kataskevi-istoselidon">Κατασκευή Ιστοσελίδων</router-link></li>
                                    <li><router-link to="/ecommerce-online-payments">Ecommerce / Online Payments</router-link></li>
                                    <li><router-link to="/veltistopoihsi-istoselidas">Βελτιστοποίηση Ιστοσελίδων</router-link></li>
                                    <li><router-link to="/ypostorixi-istoselidon">Υποστήριξη Ιστοσελίδων</router-link></li>
                                    <li><router-link to="/services#digital-agencies">για Digital Agencies</router-link></li>
                              </ul>
                           </div>
                           <div class="widget-btn mt-30">
                              <router-link to="/contact" class="tp-btn w-100">Ζητήστε προσφορά</router-link>
                           </div>
                        </div>
                  </div>
               </div>
            </div>
         </section>
</template>

<script>
export default {
    name:'ServicesDetailsArea'
}
</script>