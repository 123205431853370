<template>
       <div class="about-area pt-120 pb-90">
            <div class="container">
               <div class="row">
                  <div class="col-xl-5 col-lg-6">
                     <div class="ab-box pl-50 mb-30">
                        <div class="sec-wrapper">
                           <h5>Λίγα λόγια για μας</h5>
                           <h2 class="abs-item-title">Είμαστε εδώ για να σας καθοδηγήσουμε, να δώσουμε χρώμα και να σχεδιάσουμε ηλεκτρονικά τη νέα σας επιχειρηματική ιδέα.</h2>
                           <p>Η ASK IT Engineering με έδρα την Κέρκυρα δραστηριοποιείται στον σχεδιασμό & ανάπτυξη εφαρμογών πληροφορικής. Προσφέρει ποιοτικές, αξιόπιστες λύσεις σε λογικές τιμές. Ιδρύθηκε το 2013 και από τότε η ομάδα μας έχει αναπτύξει την κατάλληλη τεχνογνωσία που μας κάνει ανταγωνιστικούς στον χώρο των υπηρεσιών διαδικτύου. Έχουμε αναπτύξει ένα μεγάλο δίκτυο πελατών που εκτείνεται σε πολλές πόλεις σε όλο τον κόσμο όπως η Σαγκάη, το Χονγκ Κονγκ, το Λονδίνο, το Άμστερνταμ, το Τέξας, το Βερολίνο και άλλες.</p>
                           <p>Δουλεύοντας πάνω από 10 χρόνια  στον χώρο των υπηρεσιών διαδικτύου, έχουμε βοηθήσει πολλές εταιρείες να αναπτυχθούν ψηφιακά μέσω των μοντέρνων και σύγχρονων ιδεών μας. Περνώντας ώρες συζητήσεων, φλιτζανιών καφέ και προγραμματισμού, καταλήγουμε στη σωστή στρατηγική για κάθε εταιρικό μας πελάτη.
</p>
                        </div>
                     </div>
                  </div>
                  <div class="col-xl-7 col-lg-6">
                     <div class="abs-images abs-images-2 pl-50">
                        <div class="row">
                           <div class="col-7">
                              <div class="abs-img img-filter mb-30">
                                 <img src="../../assets/img/about/achievement-1.jpg" alt="">
                              </div>
                           </div>
                           <div class="col-5">
                              <div class="abs-img img-filter mb-30">
                                 <img src="../../assets/img/about/achievement-2.jpg" alt="">
                              </div>
                              <div class="ab-line-shape w-100 mb-20"></div>
                              <div class="ab-line-shape w-50"></div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
</template>

<script>
export default {
   name:'HomeTwoAbout'
}
</script>